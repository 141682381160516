import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { animationInTime, animationInTimings } from '../constants';

export const fadeStaggerInAnimation = trigger('fadeStaggerInAnimation', [
  transition('* => *', [
    query(
      ':enter',
      [
        style({ opacity: 0, transform: 'scale(0.95)' }),
        stagger(animationInTime, [animate(animationInTimings, style({ opacity: 1, transform: 'scale(1)' }))]),
      ],
      { optional: true },
    ),
  ]),
]);
