export enum PagesEnum {
  ADMIN = 'admin',
  WAITER = 'waiter',
  CART = 'cart',
  MENU = 'menu',
  NOTIFICATIONS = 'notifications',
  VENUE = 'venue',
  ACCOUNT = 'account',
  SETTINGS = 'settings',
  SAVED_CARDS = 'cards',
  ORDERS = 'orders',
  ORDER_HISTORY = 'order-history',
  PAYMENT = 'payment',
  SPLASH = 'splash',
  FEEDBACK = 'feedback',
  FAQ = 'faq',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  CONTACT = 'contact',
}
