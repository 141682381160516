export const ALBANIAN = 'sq_AL';
export const BOSNIAN = 'bs_BA';
export const BULGARIAN = 'bg_BG';
export const CROATIAN = 'hr_HR';
export const CZECH = 'cs_CZ';
export const DUTCH = 'nl_NL';
export const ENGLISH_US = 'en_US';
export const FRENCH = 'fr_FR';
export const GERMAN = 'de_DE';
export const GREEK = 'el_GR';
export const HUNGARIAN = 'hu_HU';
export const ITALIAN = 'it_IT';
export const MACEDONIAN = 'mk_MK';
export const POLISH = 'pl_PL';
export const PORTUGUESE = 'pt_PT';
export const ROMANIAN = 'ro_RO';
export const RUSSIAN = 'ru_RU';
export const SERBIAN = 'sr_RS';
export const SLOVAK = 'sk_SK';
export const SLOVENIAN = 'sl_SI';
export const SPANISH = 'es_ES';
export const TURKISH = 'tr_TR';
export const UKRAINIAN = 'uk_UA';
