import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
import { animationInSlowTimings } from '../constants';

export const tryAnimation = trigger('tryAnimation', [
  transition(':enter', [
    group([
      query('.orders__card__divider', [
        style({
          display: 'block',
          opacity: '100%',
        }),
        animateChild(),
        animate(animationInSlowTimings),
      ]),
      query('.icon-arrow-down', [
        style({
          transform: 'scaleY(-100%)',
        }),
        animateChild(),
        animate(animationInSlowTimings),
      ]),
      query('.orders__card__content', [
        style({
          display: 'flex',
          opacity: '100%',
        }),
        animateChild(),
        animate(animationInSlowTimings),
      ]),
      animate(animationInSlowTimings),
    ]),
  ]),
  transition(':leave', [
    group([
      query('.orders__card__divider', [
        style({
          display: 'none',
          opacity: '0%',
        }),
        animateChild(),
        animate(animationInSlowTimings),
      ]),
      query('.icon-arrow-down', [
        style({
          transform: 'scaleY(100%)',
        }),
        animateChild(),
        animate(animationInSlowTimings),
      ]),
      query('.orders__card__content', [
        style({
          display: 'none',
          opacity: '0%',
        }),
        animateChild(),
        animate(animationInSlowTimings),
      ]),
      animate(animationInSlowTimings),
    ]),
  ]),
]);
