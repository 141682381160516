import { IconTypeEnum, PagesEnum } from '../models';
import { AppLinksTypeModel } from '../models/types';

export const BOTTOM_MENU_LINKS: AppLinksTypeModel[] = [
  {
    route: PagesEnum.MENU,
    defaultRoute: PagesEnum.MENU,
    title: 'Food menu',
    translatePath: 'food-menu',
    shortTranslatePath: 'menu',
    iconType: IconTypeEnum.WAYTR,
    icon: 'food_menu',
  },
  {
    route: PagesEnum.CART,
    defaultRoute: PagesEnum.CART,
    title: 'Cart page',
    translatePath: 'cart',
    shortTranslatePath: 'cart',
    iconType: IconTypeEnum.WAYTR,
    icon: 'cart',
  },
  {
    route: PagesEnum.WAITER,
    defaultRoute: PagesEnum.WAITER,
    title: 'Ask waiter',
    translatePath: 'waiter',
    shortTranslatePath: 'waiter',
    iconType: IconTypeEnum.WAYTR,
    icon: 'waiter',
  },
  {
    route: PagesEnum.ORDERS,
    defaultRoute: PagesEnum.ORDERS,
    title: 'Orders',
    translatePath: 'orders',
    shortTranslatePath: 'orders',
    iconType: IconTypeEnum.WAYTR,
    icon: 'orders',
  },
  {
    route: PagesEnum.ADMIN,
    defaultRoute: PagesEnum.ADMIN,
    title: 'Table administration',
    translatePath: 'table-administration',
    shortTranslatePath: 'admin',
    iconType: IconTypeEnum.WAYTR,
    icon: 'admin',
  },
];

export const MAIN_LINKS: AppLinksTypeModel[] = [
  ...BOTTOM_MENU_LINKS,
  {
    route: PagesEnum.NOTIFICATIONS,
    defaultRoute: PagesEnum.NOTIFICATIONS,
    title: 'Notifications',
    translatePath: 'notifications',
    iconType: IconTypeEnum.WAYTR,
    icon: 'notifications',
  },
];
