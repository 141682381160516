import { Environments } from './environments.enum';

export const environment = {
  environment: Environments.DEV,
  production: true,
  PORT: 4200,
  APP_URL: 'https://dev-qr.waytr.app',
  API_URL: 'https://dev-api.waytr.app',
  WS_URL: 'wss://dev-api.waytr.app',
  API_KEY: '3zLhCT3A%r6(3MTv=0%FmG,OmvicXCDI',
  LOCAL_STORAGE_KEY: 'D!ek^8g-96<zkf2oq[EqQfW}u^Mj4g/$',
  GEOLOCATION_API_URL: 'https://ipwho.is',
};
