export enum NotificationTypeEnum {
  TABLE_ACCESS_APPROVED = 'TABLE_ACCESS_APPROVED',
  NEW_USER_REQUEST = 'NEW_USER_REQUEST',
  NEW_USER_APPROVED = 'NEW_USER_APPROVED',
  NEW_ORDER = 'NEW_ORDER',
  ORDER_SERVED = 'ORDER_SERVED',
  WAITER_COMMAND_SENT = 'WAITER_COMMAND_SENT',
  WAITER_COMMAND_AKNOWLEDGED = 'WAITER_COMMAND_AKNOWLEDGED',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
}
